var $name="shell/engine/appendUTMParameters",$path="app/modules/shell/engine/appendUTMParameters.js",$this={$name,$path,};function escapeString(inputString) {
  const tagsToReplace = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
  };
  // eslint-disable-next-line prefer-arrow-callback
  return inputString?.replace(/[&<>]/g, function (tag) {
    return tagsToReplace[tag] || tag;
  });
}

const appendUTMParameters = () => {
  const domain = window.location?.hostname?.match(/\w+\.\w+$/);

  const isLocalHref = (href) => href?.startsWith('/') && (!href?.includes('http') || !href?.includes('www'));
  const seDomain = 'se.com';
  const seChinaDomain = 'electric.cn';

  // please check this condition while working in local
  if (domain && (domain[0] === seDomain || domain[0] === seChinaDomain)) {
    const urlParams = new URLSearchParams(window?.location?.search);
    // get all the buttons and anchor tags with links in it
    const anchorTags = document?.querySelectorAll('a,button');

    anchorTags?.forEach((anchor) => {
      const href = anchor?.getAttribute('href');
      try {
        if (href) {
          const anchorUrl = new URL(href, window?.location?.origin);
          const anchorParams = new URLSearchParams(anchorUrl?.search);
          const anchorDomain = anchorUrl?.hostname?.match(/\w+\.\w+$/);

          if (urlParams) {
            if (href?.includes(seDomain) || href?.includes('preferredCountry')
              || isLocalHref(href) || (anchorDomain && (anchorDomain[0] === seChinaDomain))) {
              urlParams?.forEach((value, key) => {
                const sanitizedValue = escapeString(value);
                const sanitizedKey = escapeString(key);
                if (!sanitizedValue) return;
                if (!anchorParams.has(key)) {
                  anchorParams.append(sanitizedKey, sanitizedValue);
                }

                anchorUrl.search = anchorParams?.toString();
                anchor.setAttribute('href', anchorUrl?.href);
              });
            }
          }
        }
      } catch (error) {
        console.error('Error occurred while manipulating anchor tags:', error);
      }
    });
  }
};

export default appendUTMParameters;
